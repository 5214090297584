<template>
  <div class="container">
    <div class="login-box">
      <h1>调价先锋</h1>
      <el-form :rules="loginFormRules" ref="loginFormRef" label-width="0px" class="login_form" :model="loginForm">
        <!-- <div class="textbox">
          <input type="text" placeholder="用户名" name="username" required>
        </div> -->
        <el-form-item prop="username" class="textbox">
          <el-input size="small" v-model="loginForm.username" clearable prefix-icon="iconfont icon-user"></el-input>
        </el-form-item>
        <el-form-item prop="password" class="textbox">
          <el-input size="small" type="password" v-model="loginForm.password" clearable prefix-icon="iconfont icon-3702mima"></el-input>
        </el-form-item>
        <el-form-item class="btns">
          <button type="submit" @click.prevent="login" :loading="loginLoading" class="btn">登录</button>
          <button type="reset" @click="resetLoginForm" class="btn">重置</button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'Login',
  data() {
    return {
      loginLoading: false, // 登录限制
      loginForm: {
        // 登录的表单数据的绑定对象
        username: '',
        password: ''
      },
      loginFormRules: {
        // 验证用户名是否合法
        username: [
          { required: true, message: '请输入登录名称', trigger: 'blur' }
        ],
        // 验证密码是否合法
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    ...mapActions(['userSession']),
    resetLoginForm() {
      // 点击重置按钮,重置登录表单
      // this.$refs[loginFormRef].resetFields()
      this.$refs.loginFormRef.resetFields()
    },
    async login() {
      this.loginLoading = true
      this.$refs.loginFormRef.validate(async valid => {
        if (!valid) {
          return (this.loginLoading = false)
        }
        try {
          let info = await this.userSession(this.loginForm)
          if (info.message) {
            return this.$message.error(info.message)
          }
          let rule = info.role
          window.localStorage.setItem('rule', rule)
          this.$message.success('登录成功!')
          this.$router.push('/user-center-new')
        } catch {
          this.$message.error('登录失败 帐号或密码错误!')
        } finally {
          this.loginLoading = false
        }
        
        // const { data: res } = await this.$http.post('login', this.loginForm)
        // if (res.meta.status !== 200) {
        //   this.loginLoading = false
        //   return this.$message.error('登录失败 帐号或密码错误!')
        // }
        // this.$message.success('登录成功!')
        // // 1. 将登录成功之后的 token,保存到客户端的 sessionStorage(会话机制/只在当前页面生效)中 localStorage(持久话机制/关闭页面也不会忘记数据)
        // //   1.1 项目中除了登录之外的API接口,必须在登录之后才能访问
        // //   1.2 token 只应在当前网站打开期间生效, 所以将 token 保存在 sessionStorage中
        // window.sessionStorage.setItem('token', res.data.token)
        // // 2. 通过编程式路由导航跳转到后台主页,路由地址是 /home
        // this.$router.push('/home')
        // this.loginLoading = false
      })
    }
  }
}
</script>

<style lang="less">

body {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #2980b9, #2c3e50);
  font-family: 'Roboto', sans-serif;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.login-box {
  width: 400px;
  padding: 40px;
  position: relative;
  background: #fff;
  text-align: center;
  box-shadow: 0 10px 20px rgba(0,0,0,0.2);
  border-radius: 10px;
}

.login-box h1 {
  margin: 0 0 30px;
  padding: 0;
  font-size: 36px;
  color: #333;
}

.textbox {
  position: relative;
  margin-bottom: 30px;
}

.textbox input {
  width: 100%;
  padding: 10px;
  background: #f2f2f2;
  border: none;
  outline: none;
  color: #333;
  font-size: 18px;
  border-radius: 5px;
}

.btn {
  width: 100%;
  background: #2980b9;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
  color: white;
  border-radius: 5px;
  margin: 10px 0;
  transition: background 0.3s;

  &:hover {
    background: #2c3e50;
  }

  &:focus {
    outline: none;
  }
}
</style>