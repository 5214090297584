<template>
  <el-container class="home-container">
    <!-- 头部区域 -->
    <el-header>
      <div>
        <img src="@/assets/logo.svg" alt="调价先锋" @click="goOfferList" class="logo" />
        <el-menu class="menu" text-color="#000" active-text-color="#1890ff" :unique-opened="true"
          :collapse-transition="false" :router="true" :default-active="activePath" mode="horizontal">
          <!-- 一级菜单 -->
          <el-submenu :index="item.id + ''" v-for="item in menuList" :key="item.id">
            <!-- 一级菜单的模板区域 -->
            <template slot="title">
              <!-- 图标 -->
              <i :class="iconsObj[item.id]"></i>
              <!-- 文本 -->
              <span>{{ item.authName }}</span>
            </template>
            <!-- 二级菜单 -->
            <el-menu-item :index="'/' + sunItem.path" v-for="sunItem in item.children" :key="sunItem.id"
              @click="saveActivePath('/' + sunItem.path)">
              <template slot="title">
                <!-- 二级菜单的模板区域 -->
                <i class="el-icon-menu"></i>
                <!-- 图标 -->
                <span>{{ sunItem.authName }}</span>
                <!-- 文本 -->
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
      <div>
        <el-tooltip class="item" effect="dark" placement="bottom">
          <i style="cursor:pointer;margin-right:10px;" class="el-icon-view" @click="more">
            使用说明
          </i>
          <div slot="content">点击查看使用说明</div>
        </el-tooltip>
        <el-popover placement="bottom" width="150" trigger="click">
          <div slot="reference">
            <div class="user-info-wrap">
              <div class="user-info">登录账号：{{ userInfo.username }}</div>
              <i class="el-icon-arrow-down"></i>
            </div>
            <div class="nearly-time">
              {{ nearlyTime }}
            </div>
          </div>

          <div class="action-list">
            <!-- <div class="item" @click="goUserCenter">个人中心</div> -->
            <div class="item" @click="editUserInfo">修改密码</div>
            <div class="item" @click="logout">退出</div>
          </div>
        </el-popover>
      </div>
    </el-header>
    <el-container class="content-container">
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
    <el-dialog :close-on-click-modal=false title="修改密码" :visible.sync="visible" width="50%" @close="dialogClosed">
      <el-form :model="userInfo" ref="userForm" :rules="rule" label-width="100px">
        <el-form-item label="用户名:" prop="username">
          <el-input style="width:350px;" v-model.trim="userInfo.username" disabled></el-input>
        </el-form-item>
        <el-form-item label="旧密码:" prop="password">
          <el-input style="width:350px;" v-model.trim="userInfo.password" show-password type="password"></el-input>
        </el-form-item>

        <el-form-item label="新密码:" prop="newPassword1">
          <el-input style="width:350px;" v-model.trim="userInfo.newPassword1" show-password type="password"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码:" prop="newPassword2">
          <el-input style="width:350px;" v-model.trim="userInfo.newPassword2" show-password type="password"></el-input>
        </el-form-item>
      </el-form>
      <el-button :loading="loading" size="small" type="primary" @click="save">保存</el-button>
    </el-dialog>
  </el-container>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
export default {
  name: 'Home',
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.userInfo.password1) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      nearlyTime: '',
      // 用户信息相关
      userInfo: {},
      rule: {
        password: [
          { required: true, message: '请输入旧密码', trigger: 'blur' }
        ],
        newPassword1: [
          { required: true, message: '请输入新密码', trigger: 'blur', }
        ],
        newPassword2: [
          { required: true, message: '再次请输入新密码', trigger: 'blur', validate: validatePass2 }
        ],
        apiKey: [
          { required: true, message: '请输入apiKey', trigger: 'blur' }
        ]
      },
      loading: false,
      visible: false,
      // 左侧菜单数据
      menuList: [],
      iconsObj: {
        // 一级菜单的icon图标
        101: 'iconfont icon-users',
        102: 'el-icon-odometer',
        103: 'iconfont icon-tijikongjian',
        104: 'el-icon-user-solid',
        147: 'el-icon-magic-stick',
        125: 'iconfont icon-users',
        145: 'iconfont icon-baobiao'
      },
      activePath: '',
      welcome: {
        authName: '欢迎光临',
        id: 147,
        order: 1,
        path: '/welcome',
        children: [
          {
            authName: 'Welcome',
            id: 124,
            order: 1,
            path: 'welcome',
            children: []
          }
        ]
      }
    }
  },

  watch: {
    '$route': {
      immediate: true,
      async handler(val) {
        this.activePath = val.path
        if (!this.userInfo.username) {
          await this.getUser()
          this.getMenuList()
        }
      }
    }
  },
  async created() {
    this.getMenuList()
    // this.activePath = window.sessionStorage.getItem('activePath')
  },
  methods: {
    convertSecondsToHMS(seconds) {
      let hours = Math.floor(seconds / 3600);
      let minutes = Math.floor((seconds % 3600) / 60);
      let remainingSeconds = seconds % 60;
      return hours + "小时 " + minutes + "分钟 " + remainingSeconds + "秒";
    },
    more() {
      window.open('https://note.youdao.com/s/C7cGCmb0', '_blank')
    },
    ...mapMutations({
      setCollapse: 'SET_COLLAPSE'
    }),
    ...mapActions(['getUserInfo', 'updateUserInfo', 'logOut']),
    // 获取用户信息
    async getUser() {
      const info = await this.getUserInfo()
      // this.userInfo.username = info.username
      this.userInfo = info
      this.getNearlyTime()
      // window.localStorage.setItem('rule', this.userInfo.role)
    },
    getNearlyTime() {
      let now = Date.now() / 1000; // 获取当前时间戳并转换为秒
      let { to } = this.userInfo;
      if (to <= now) {
        this.nearlyTime = '当前账户已到期'
      } else {
        let days = Math.ceil((to - now) / (60 * 60 * 24))
        if (days <= 3) {
          this.nearlyTime = `当前账户有效期已不足${days}天`
        }
      }
    },
    // 打开用户信息弹窗
    async editUserInfo() {
      await this.getUser();
      this.visible = true
    },
    // 修改用户信息
    async save() {
      this.$refs.userForm.validate(async valid => {
        if (!valid) return
        this.loading = true
        try {
          await this.updateUserInfo({
            password: this.userInfo.password,
            newPassword1: this.userInfo.newPassword1,
            newPassword2: this.userInfo.newPassword2
          })
          this.$message.success('修改成功')
          this.visible = false
        } catch (e) {
          this.$message.error('修改失败，请重试')
        } finally {
          this.loading = false
        }
      })
    },
    dialogClosed() {
      this.$refs.userForm.resetFields()
    },
    goUserCenter() {
      this.$router.push({
        path: '/user-center'
      })
    },
    async logout() {
      await this.logOut()
      window.sessionStorage.clear()
      window.localStorage.clear()
      this.$router.push('/login')
    },
    // 获取所有的菜单
    async getMenuList() {
      // 1：管理员 2：商户
      let superAdmin = [
        {
          authName: '超级管理员',
          id: 101,
          order: 1,
          path: '/admin',
          children: [
            {
              authName: '用户列表',
              id: 121,
              order: 1,
              path: 'user-list',
              children: []
            },
            {
              authName: 'offer列表',
              id: 122,
              order: 2,
              path: 'offer-list',
              children: []
            }
          ]
        }]

        let shopAdmin = [
        {
          authName: '店铺管理员',
          id: 101,
          order: 1,
          path: '/admin',
          children: [
            {
              authName: 'offer列表',
              id: 122,
              order: 2,
              path: 'offer-list',
              children: []
            }
          ]
        }] 
      let mall = [
        {
          authName: '我的',
          id: 104,
          order: 1,
          path: '/admin',
          children: [
            {
              authName: '我的店铺',
              order: 1,
              path: 'user-center-new',
              children: []
            },
            // {
            //   authName: '调价中心',
            //   order: 2,
            //   path: 'update-price-center',
            //   children: []
            // }
          ]
        }
      ]
      let rule = window.localStorage.getItem('rule')
      if (+rule === 1) { // 管理员
        this.menuList = superAdmin.concat(mall);
      } else if (+rule === 3) {
        this.menuList = shopAdmin.concat(mall);
      } else {
        this.menuList = mall
      }
    },
    saveActivePath(activePath) {
      // window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath
      // console.log(this.$route.path)
    },
    goOfferList() {
      if (this.$route.path !== '/user-center-new') {
        this.$router.push('/user-center-new')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.nearly-time {
  color: #f56c6c;
  font-size: 14px;
}

.user-info-wrap {
  display: flex;
  align-items: center;
  color: #bbb;
  cursor: pointer;

  .user-info {
    font-size: 16px;
    max-width: 150px;
    margin-right: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
  }
}

.action-list {
  .item {
    height: 25px;
    line-height: 25px;
    font-size: 14px;
    cursor: pointer;
  }
}

.content-container {
  height: calc(100% - 60px);
}

.hwelcome {
  color: #fff;
  font-size: 15px;
  height: 25px;
  text-align: center;
  background: green;

  span {
    margin-left: 10px;
  }
}

.home-container {
  width: 100vw;
  height: 100%;
}

.el-header {
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  background-color: #fff;
  align-items: center;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  font-size: 20px;

  >div {
    display: flex;
    align-items: center;

    img {
      cursor: pointer;
      // width: 100%;
      height: 60px;
      // border-radius: 50%;
    }

    span {
      // margin-left: 15px;
    }
  }
}

.el-aside {
  background-color: #333744;

  .el-menu {
    border-right: none;
  }
}

.el-main {
  background-color: #eaedf1;
  width: calc(100% -200px);
}

.menu {
  border-bottom: none;
}

.iconfont {
  padding-right: 10px;
}

.toggle-button {
  background: #4a5064;
  font-size: 10px;
  text-align: center;
  line-height: 24px;
  color: #fff;
  letter-spacing: 0.2em;
  cursor: pointer;
}
.svg {
      cursor: pointer;
      height: 60px; // 设置固定高度
      width: auto; // 宽度自动调整以保持比例
    }
</style>
